import cf450 from "../../../images/home/ATV 450.jpg";
import cf450_long from "../../../images/home/ATV 450 LONG.jpg";
import cf520_long from "../../../images/home/atv520.jpeg";
import sym50 from "../../../images/home/sym50cc.jpg";
import sym125 from "../../../images/home/SYM 125.jpg";
import sym200 from "../../../images/home/sym200.jpg";

const scooterContent = [

  {
    title: "Sym sr 50cc",
    images: sym50,
  },
  {
    title: "Sym sr 125cc",
    images: sym125,
  },
  {
    title: "Sym st 200cc",
    images: sym200,
  },
  {
    title: "Cforce atv 450cc",
    images: cf450,
  },
  {
    title: "Cforce atv 450cc long",
    images: cf450_long,
  },
  {
    title: "Cforce atv 520cc long",
    images: cf520_long,
  }
];

export default scooterContent;
