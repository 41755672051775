import cf450 from "../../../../images/home/ATV 450.jpg";
import cf450_long from "../../../../images/home/ATV 450 LONG.jpg";
import cf520_long from "../../../../images/home/atv520.jpeg";
import sym50 from "../../../../images/home/sym50cc.jpg";
import sym125 from "../../../../images/home/SYM 125.jpg";
import sym200 from "../../../../images/home/sym200.jpg";

export const scooterItems = [
  {
    id: 1,
    img: sym50,
    title: "Sym sr 50cc"
  },
  {
    id: 2,
    img: sym125,
    title: "Sym sr 125cc"
  },
  {
    id: 3,
    img: sym200,
    title: "Sym st 200cc"
  },
  {
    id: 4,
    img: cf450,
    title: "Cforce atv 450cc"
  },
  {
    id: 5,
    img: cf450_long,
    title: "Cforce atv 450cc long"
  },
  {
    id: 6,
    img: cf520_long,
    title: "Cforce atv 520cc long"
  }
]