import React, { useEffect, useRef } from "react";
import "./CopyrightsFooterSection.css";
import { useLanguage } from "../../../contexts/LanguageContext";
import { translations } from "../translations";

const CopyrightsFooterSection = () => {
  const { language } = useLanguage();
  const content = translations[language];

  const copyrightRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      root: null,
      threshold: 0.2, // Trigger when 20% of element is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        }
      });
    }, observerOptions);

    if (copyrightRef.current) observer.observe(copyrightRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <p ref={copyrightRef} className="copyright animate-copyright">
      {content.footerCopyright}
    </p>
  );
};

export default CopyrightsFooterSection;
