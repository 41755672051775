const translations = {
  EN: {
    formTitle: "Scooter Reservation",
    sendingMessage: "Sending reservation...",
    fixErrorsMessage: "Fix the errors and try again.",
    errorMessage: "An error occurred. Please try again.",
    fields: {
      name: "Name",
      email: "Email",
      mobile: "Mobile Number",
      startDateTime: "Rent From",
      endDateTime: "Rent Until",
      hasLicense: "I own a motorcycle license (Required)",
      hasCarLicense: "I own a car driver's license (Required for Quads)",
      selectedScooter: "Select A Scooter",
      selectScooter: "-- Select a Scooter --",
      submit: "Reserve Now",
    },
    errors: {
      name: "Name is required.",
      email: "Email is required.",
      mobile: "Mobile number is required and must contain only numbers.",
      startDateTime: "Start date and time are required.",
      endDateTime: "End date and time are required.",
      invalidEndDate: "End date/time must be after start date/time.",
      hasLicense: "You must have a motorcycle license.",
      selectedScooter: "Please select a scooter.",
    },
  },
  GR: {
    formTitle: "Κράτηση Σκούτερ",
    sendingMessage: "Αποστολή κράτησης...",
    fixErrorsMessage: "Διορθώστε τα λάθη και προσπαθήστε ξανά.",
    errorMessage: "Παρουσιάστηκε σφάλμα. Δοκιμάστε ξανά.",
    fields: {
      name: "Όνομα",
      email: "Email",
      mobile: "Αριθμός Κινητού",
      startDateTime: "Ενοικίαση από",
      endDateTime: "Ενοικίαση έως",
      hasLicense: "Έχω δίπλωμα μοτοσικλέτας (Απαραίτητο)",
      hasCarLicense: "Έχω δίπλωμα αυτοκινήτου (Απαραίτητο για Quads)",
      selectedScooter: "Επιλέξτε ένα σκούτερ",
      selectScooter: "-- Επιλέξτε ένα σκούτερ --",
      submit: "Κάντε Κράτηση",
    },
    errors: {
      name: "Το όνομα είναι απαραίτητο.",
      email: "Το email είναι απαραίτητο.",
      mobile: "Ο αριθμός κινητού πρέπει να περιέχει μόνο αριθμούς.",
      startDateTime: "Η ημερομηνία έναρξης είναι απαραίτητη.",
      endDateTime: "Η ημερομηνία λήξης είναι απαραίτητη.",
      invalidEndDate: "Η ημερομηνία λήξης πρέπει να είναι μετά την ημερομηνία έναρξης.",
      hasLicense: "Πρέπει να έχετε δίπλωμα μοτοσικλέτας.",
      selectedScooter: "Επιλέξτε ένα σκούτερ.",
    },
  },
  IT: {
    formTitle: "Prenotazione Scooter",
    sendingMessage: "Invio prenotazione...",
    fixErrorsMessage: "Correggi gli errori e riprova.",
    errorMessage: "Si è verificato un errore. Riprova.",
    fields: {
      name: "Nome",
      email: "Email",
      mobile: "Numero di cellulare",
      startDateTime: "Noleggio da",
      endDateTime: "Noleggio fino a",
      hasLicense: "Possiedo una patente per motocicli (Obbligatorio)",
      hasCarLicense: "Possiedo una patente per auto (Obbligatoria per Quad)",
      selectedScooter: "Seleziona uno Scooter",
      selectScooter: "-- Seleziona uno Scooter --",
      submit: "Prenota Ora",
    },
    errors: {
      name: "Il nome è obbligatorio.",
      email: "L'email è obbligatoria.",
      mobile: "Il numero di cellulare deve contenere solo numeri.",
      startDateTime: "La data di inizio è obbligatoria.",
      endDateTime: "La data di fine è obbligatoria.",
      invalidEndDate: "La data di fine deve essere successiva alla data di inizio.",
      hasLicense: "Devi avere una patente per motocicli.",
      selectedScooter: "Seleziona uno scooter.",
    },
  },
  GER: {
    formTitle: "Roller Reservierung",
    sendingMessage: "Reservierung wird gesendet...",
    fixErrorsMessage: "Korrigieren Sie die Fehler und versuchen Sie es erneut.",
    errorMessage: "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.",
    fields: {
      name: "Name",
      email: "Email",
      mobile: "Handynummer",
      startDateTime: "Mieten ab",
      endDateTime: "Mieten bis",
      hasLicense: "Ich besitze einen Motorradführerschein (Erforderlich)",
      hasCarLicense: "Ich besitze einen Autoführerschein (Erforderlich für Quads)",
      selectedScooter: "Wählen Sie einen Roller",
      selectScooter: "-- Wählen Sie einen Roller --",
      submit: "Jetzt Reservieren",
    },
    errors: {
      name: "Der Name ist erforderlich.",
      email: "Die E-Mail ist erforderlich.",
      mobile: "Die Handynummer darf nur Zahlen enthalten.",
      startDateTime: "Startdatum und -zeit sind erforderlich.",
      endDateTime: "Enddatum und -zeit sind erforderlich.",
      invalidEndDate: "Das Enddatum muss nach dem Startdatum liegen.",
      hasLicense: "Sie müssen einen Motorradführerschein besitzen.",
      selectedScooter: "Bitte wählen Sie einen Roller aus.",
    },
  },
};

export { translations };
